import CryptoJS from 'crypto-js';
const password = 'tapwallet-123'

export const getPRVInscriptionContent = async (ticker) => {
  const url = `https://api2.tapwallet.io/_signAuthRandomNew`;

  const data = {
    ticker: ticker
  }
  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const cancelPRVInscriptionContent = async (ticker, pubKey) => {
  const url = `https://api2.tapwallet.io/_signAuthRandomNew/cancel`;

  const data = {
    ticker: ticker,
    pubKey: pubKey
  }
  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const getPATokenMintTickers = async () => {
  const url = `https://api2.tapwallet.io/patoken/mint/tickers`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const getPATokenDeployTickers = async () => {
  const url = `https://api2.tapwallet.io/patoken/deploy/tickers`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const checkPATokenDeployTicker = async (ticker) => {
  const url = `https://api2.tapwallet.io/patoken/deploy/ticker/check?ticker=${ticker}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}


export const getPATokenDetail = async (ticker) => {
  const url = `https://api2.tapwallet.io/patoken/get?ticker=${ticker}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const savePATokenDetail = async (data) => {

  const url = `https://api2.tapwallet.io/patoken/register`;

  data.password = CryptoJS.AES.encrypt(data.passwordKey, password).toString()

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const getPATokenWhitelistAddresses = async (number) => {
  const url = `https://api2.tapwallet.io/patoken/whitelist/get?number=${number}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const getPATokenWhitelistAddressCheck = async (number, address) => {
  const url = `https://api2.tapwallet.io/patoken/whitelist/check?number=${number}&address=${address}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();

}

export const savePATokenWhitelistAddresses = async (data) => {

  const url = `https://api2.tapwallet.io/patoken/whitelist/register`;

  data.password = CryptoJS.AES.encrypt(data.passwordKey, password).toString()

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}

export const checkPassword = async (oldPassword, ticker) => {
  const url = `https://api2.tapwallet.io/patoken/checkPassword`;

  const data = {
    oldPassword:  CryptoJS.AES.encrypt(oldPassword, password).toString(),
    ticker: ticker
  }

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const updatePATokenPassword = async (oldPassword, newPassowrd, ticker) => {

  const url = `https://api2.tapwallet.io/patoken/updatePassword`;

  const data = {
    oldPassword:  CryptoJS.AES.encrypt(oldPassword, password).toString(),
    newPassword:  CryptoJS.AES.encrypt(newPassowrd, password).toString(),
    ticker: ticker
  }

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();

}


export const checkWhiteListedDomain = async (key) => {

  const url = `https://api.tapwallet.io/domain/whitelist?key=${key}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerPaymentNew = async (key, address, inscriptionAddress = "") => {
  
  const url = 'https://api.tapwallet.io/new_payment/register';
  const data = {
    key: key,
    address: address,
    inscription_address: inscriptionAddress,
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const registerPaymentRandom = async (key, address, number, inscriptionAddress = "") => {
  
  const url = 'https://api.tapwallet.io/random_payment/register';
  const data = {
    key: key,
    address: address,
    inscription_address: inscriptionAddress,
    number: number
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const checkTesterAddress = async (key, offset = 1, limit = 10) => {

  const url = `https://api.tapwallet.io/token/latest?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const latestTokenNew = async (key, offset = 1, limit = 10) => {

  const url = `https://api.tapwallet.io/new_token/latest?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const latestTokenRandom = async (key, number,  offset = 1, limit = 10) => {

  const url = `https://api.tapwallet.io/random_token/latest?key=${key}&offset=${offset}&limit=${limit}&number=${number}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const checkWhiteListedAddress = async (key) => {

  const url = `https://api.tapwallet.io/check/whitelisted?key=${key}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerTokenNew = async (address, transaction) => {
  
  const url = 'https://api2.tapwallet.io/new_token/register';
  const data = {
    address: address,
    transaction: transaction,
    data: CryptoJS.AES.encrypt(transaction, password).toString(),
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const registerTokenRandom = async (address, transaction, number, amount) => {
  
  const url = 'https://api2.tapwallet.io/random_token/register';
  const data = {
    address: address,
    transaction: transaction,
    data: CryptoJS.AES.encrypt(transaction, password).toString(),
    number: number,
    amount: amount
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const getMintStatus = async () => {
  
  const url = `https://api.tapwallet.io/mint/status`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getPaymentUtxos = async (paymentAddress, inscriptionAddress, inscriptionAmount, fundingAddress, fundingAmount, paymentPubkey, feeRate, amount, provider) => {
  
  const url = 'https://api.tapwallet.io/getPaymentUtxos';
  const data = {
    paymentAddress: paymentAddress,
    inscriptionAddress: inscriptionAddress,
    inscriptionAmount: inscriptionAmount,
    fundingAddress: fundingAddress,
    fundingAmount: fundingAmount,
    paymentPubkey: paymentPubkey,
    feeRate: feeRate,
    amount: parseInt(amount),
    provider: provider
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPaymentUtxosRandom = async (paymentAddress, inscriptionAddress, inscriptionAmount, fundingAddress, fundingAmount, royaltyAddress, royaltyAmount, paymentPubkey, feeRate, amount, provider) => {
  
  const url = 'https://api.tapwallet.io/getPaymentUtxosRandom';
  const data = {
    paymentAddress: paymentAddress,
    inscriptionAddress: inscriptionAddress,
    inscriptionAmount: inscriptionAmount,
    fundingAddress: fundingAddress,
    fundingAmount: fundingAmount,
    royaltyAddress: royaltyAddress,
    royaltyAmount: royaltyAmount,
    paymentPubkey: paymentPubkey,
    feeRate: feeRate,
    amount: parseInt(amount),
    provider: provider
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPaymentUtxosTemp = async (paymentAddress, inscriptionAddress, inscriptionAmount, fundingAddress, fundingAmount, paymentPubkey, feeRate, amount) => {
  
  const url = 'https://api.tapwallet.io/getPaymentUtxosTemp';
  const data = {
    paymentAddress: paymentAddress,
    inscriptionAddress: inscriptionAddress,
    inscriptionAmount: inscriptionAmount,
    fundingAddress: fundingAddress,
    fundingAmount: fundingAmount,
    paymentPubkey: paymentPubkey,
    feeRate: feeRate,
    amount: parseInt(amount)
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getSignMintTextNew = async (address, amount) => {
  
  const url = 'https://api2.tapwallet.io/_signMintNew';
  const data = {
    address: address,
    amount: parseInt(amount)
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  let result = await response.text();
  const bytes  = CryptoJS.AES.decrypt(result, password);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  
  return originalText;
}

export const getSignMintTextRandom = async (address, amount, number) => {
  
  const url = 'https://api2.tapwallet.io/_signMintRandom';
  const data = {
    address: address,
    amount: parseInt(amount),
    number: number
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  let result = await response.text();
  const bytes  = CryptoJS.AES.decrypt(result, password);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  
  return originalText;
}


export const getPaymentHistoryNew = async () => {
  const url = 'https://api.tapwallet.io/payment_history_new';

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const savePaymentHistoryNew = async (value) => {
  const url = 'https://api.tapwallet.io/payment_history_new';
  const data = {
    value: value
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPaymentTx = async (psbtBase64) => {
  
  const url = 'https://api.tapwallet.io/getPaymentTx';
  const data = {
    psbtBase64: psbtBase64
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}
